import CircleIcon from '@mui/icons-material/Circle';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Logo from '../../assets/images/LogoWithText.svg';
import Link from '../Link';
import MobileNavigation from '../MobileNavigation';

const activeStyle = {
  textDecoration: 'underline',
  textDecorationThickness: '1px',
  textUnderlineOffset: '2px',
};

const linkStyle = {
  color: 'black',
  '&:hover': {
    textDecorationThickness: '1px',
    textUnderlineOffset: '2px',
  },
};

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        background: scrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
        backdropFilter: scrolled ? 'blur(20px) brightness(0.8)' : 'none',
        WebkitBackdropFilter: scrolled ? 'blur(20px) brightness(0.8)' : 'none',
        transition: 'background 0.3s ease, backdrop-filter 0.3s ease',
        zIndex: 1000,
      }}
      component="header"
    >
      <Stack
        direction="row"
        p={4}
        justifyContent="space-between"
      >
        <Link
          to="/"
          variant="body1"
          underline="none"
          display="flex"
          sx={{ color: 'black' }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              '& svg': {
                maxHeight: { xs: '40px', lg: '80px' },
                maxWidth: { xs: '170px', lg: '340px' },
              },
            }}
          >
            <Logo />
          </Box>
        </Link>
        <Stack
          direction="row"
          alignItems="center"
          display={{ xs: 'flex', lg: 'none' }}
        >
          <MobileNavigation />
        </Stack>
        <Stack
          direction="row"
          display={{ xs: 'none', lg: 'flex' }}
        >
          <Stack
            direction="row"
            spacing={4}
            useFlexGap
          >
            <Link
              to="/"
              variant="body1"
              underline="hover"
              fontSize={{ md: 20, xl: 24 }}
              activeStyle={activeStyle}
              sx={linkStyle}
            >
              Startseite
            </Link>
            <Link
              to="/prescription"
              variant="body1"
              underline="hover"
              fontSize={{ md: 20, xl: 24 }}
              activeStyle={activeStyle}
              sx={linkStyle}
            >
              Rezept
            </Link>
            <Link
              href="https://www.doctolib.de/praxis/worms/die-hausaerzte-worms-nickels-herzog?utm_campaign=website-button&amp;utm_source=die-hausaerzte-worms-nickels-herzog-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=die-hausaerzte-worms-nickels-herzog"
              variant="body1"
              underline="hover"
              fontSize={{ md: 20, xl: 24 }}
              activeStyle={activeStyle}
              sx={linkStyle}
            >
              Termin
            </Link>
            <Link
              to="#opening-hours"
              variant="body1"
              underline="hover"
              fontSize={{ md: 20, xl: 24 }}
              activeStyle={activeStyle}
              sx={linkStyle}
            >
              Öffnungszeiten
            </Link>
            <Link
              to="/about"
              variant="body1"
              underline="hover"
              fontSize={{ md: 20, xl: 24 }}
              activeStyle={activeStyle}
              sx={linkStyle}
            >
              Über uns
            </Link>
          </Stack>
          <Stack
            ml={4}
            px={0.5}
            py={{ md: 0.5, xl: 1 }}
          >
            <CircleIcon sx={{ color: '#EE733C', fontSize: '20px' }} />
          </Stack>
          <Stack
            direction="column"
            useFlexGap
          >
            <Typography
              variant="body1"
              fontSize={{ md: 20, xl: 24 }}
              sx={{ color: 'black' }}
            >
              Kontakt
            </Typography>
            <Link
              href="tel:+49624144555"
              variant="body1"
              underline="always"
              fontSize={14}
              fontWeight={300}
              sx={{ color: 'black', textDecorationColor: 'black' }}
            >
              Tel.: 06241 44 555
            </Link>
            <Link
              href="tel:+496241490107"
              variant="body1"
              underline="always"
              fontSize={14}
              fontWeight={300}
              sx={{ color: 'black', textDecorationColor: 'black' }}
            >
              Fax: 06241 490 107
            </Link>
            <Link
              href="https://www.doctolib.de/praxis/worms/die-hausaerzte-worms-nickels-herzog?utm_campaign=website-button&amp;utm_source=die-hausaerzte-worms-nickels-herzog-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=die-hausaerzte-worms-nickels-herzog"
              variant="body1"
              underline="always"
              fontSize={14}
              fontWeight={300}
              sx={{ color: 'black', textDecorationColor: 'black' }}
            >
              Termin vereinbaren
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Header;
