import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PPObjectSansRegularWoff from '../../assets/fonts/PPObjectSans-Regular.woff';
import PPObjectSansRegularWoff2 from '../../assets/fonts/PPObjectSans-Regular.woff2';
import Footer from '../Footer';
import Header from '../Header';

const theme = createTheme({
  typography: {
    fontFamily: ['"PP Object Sans Regular", Roboto, "Helvetica Neue", Arial, sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                    font-family: 'PP Object Sans Regular';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 300;
                    src: url(${PPObjectSansRegularWoff2}) format('woff2'),
                         url(${PPObjectSansRegularWoff}) format('woff');
                }
            `,
    },
  },
});

const queryClient = new QueryClient();

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Header />
        <Container
          maxWidth={false}
          disableGutters
          component="main"
        >
          {children}
          <Footer />
        </Container>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default Layout;
