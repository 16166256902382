import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Drawer, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { navigate, useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import Link from '../Link';

const activeStyle = {
  textDecoration: 'underline',
  textDecorationThickness: '1px',
  textUnderlineOffset: '2px',
};

const MobileNavigation = () => {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const openingHoursLink =
    {
      '/': '/#opening-hours',
      '/about/': '/about/#opening-hours',
    }[location.pathname] ?? '/#opening-hours';

  const drawerStyle = {
    width: '100%',
    height: '100dvh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF7B2',
    color: 'rgba(0, 0, 0, 0.9)',
    overflow: 'hidden',
  };

  const handleOpeningHoursClick = (event) => {
    event.preventDefault();

    const customEvt = new CustomEvent('openingHoursExpandedEvent', {
      detail: { message: 'true' },
    });

    // Dispatch the event
    window.dispatchEvent(customEvt);
    navigate('#opening-hours');
  };

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ cursor: 'pointer' }}
        onClick={toggleDrawer(true)}
      >
        <AddIcon fontSize="large" />
      </Stack>
      <Drawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: drawerStyle }}
        disableRestoreFocus={true}
      >
        <Box
          sx={{
            width: '100%',
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(10),
            p: 4,
            overflowY: 'auto',
            scrollbarWidth: 'none', // For Firefox
            '&::-webkit-scrollbar': {
              display: 'none', // For WebKit browsers
            },
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Stack
            direction="row"
            alignItems="center"
            height={58}
            onClick={toggleDrawer(false)}
            sx={{ cursor: 'pointer', alignSelf: 'flex-end' }}
          >
            <RemoveIcon fontSize="large" />
          </Stack>
          <Stack
            direction="column"
            alignItems="flex-end"
            spacing={1}
            sx={{ flexGrow: 1 }}
            useFlexGap
          >
            <Link
              to="/"
              variant="body1"
              underline="none"
              fontSize={24}
              activeStyle={activeStyle}
              sx={{ color: 'black' }}
            >
              Startseite
            </Link>
            <Link
              to="/prescription"
              variant="body1"
              underline="none"
              fontSize={24}
              activeStyle={activeStyle}
              sx={{ color: 'black' }}
            >
              Rezept
            </Link>
            <Link
              href="https://www.doctolib.de/praxis/worms/die-hausaerzte-worms-nickels-herzog?utm_campaign=website-button&amp;utm_source=die-hausaerzte-worms-nickels-herzog-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=die-hausaerzte-worms-nickels-herzog"
              variant="body1"
              underline="none"
              fontSize={24}
              activeStyle={activeStyle}
              sx={{ color: 'black' }}
            >
              Termin
            </Link>
            <Link
              to={openingHoursLink}
              variant="body1"
              underline="none"
              fontSize={24}
              onClick={handleOpeningHoursClick}
              activeStyle={activeStyle}
              sx={{ color: 'black' }}
            >
              Öffnungszeiten
            </Link>
            <Link
              to="/about"
              variant="body1"
              underline="none"
              fontSize={24}
              activeStyle={activeStyle}
              sx={{ color: 'black' }}
            >
              Über uns
            </Link>
            <Stack
              direction="column"
              alignItems="flex-end"
              mt={0}
            >
              <Stack
                direction="row"
                spacing={0.5}
                useFlexGap
                alignItems="center"
              >
                <CircleIcon sx={{ color: '#EE733C', fontSize: '20px' }} />
                <Typography
                  variant="body1"
                  fontSize={24}
                  sx={{ color: 'black' }}
                >
                  Kontakt
                </Typography>
              </Stack>
              <Link
                href="tel:+49624144555"
                variant="body1"
                underline="always"
                fontSize={24}
                sx={{ color: 'black', textDecorationColor: 'black' }}
              >
                Tel.: 06241 44 555
              </Link>
              <Link
                href="tel:+496241490107"
                variant="body1"
                underline="always"
                fontSize={24}
                sx={{ color: 'black', textDecorationColor: 'black' }}
              >
                Fax: 06241 490 107
              </Link>
              <Link
                href="https://www.doctolib.de/praxis/worms/die-hausaerzte-worms-nickels-herzog?utm_campaign=website-button&amp;utm_source=die-hausaerzte-worms-nickels-herzog-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=die-hausaerzte-worms-nickels-herzog"
                variant="body1"
                underline="always"
                fontSize={24}
                sx={{ color: 'black', textDecorationColor: 'black' }}
              >
                Termin vereinbaren
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </Stack>
  );
};

export default MobileNavigation;
