import { Link as MUILink } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';

/*
    For links to external sites, apps etc. use <Link href="..." .../>
    For links to internal pages use <Link to="/pagename" .../>
*/

const commonStyle = {
  textDecorationThickness: '1px',
  textUnderlineOffset: '2px',
};

const Link = ({ to, href, sx, ...props }) => {
  if (to)
    return (
      <MUILink
        component={GatsbyLink}
        to={to}
        sx={{ ...commonStyle, ...sx }}
        {...props}
      />
    );

  if (href)
    return (
      <MUILink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ ...commonStyle, ...sx }}
        {...props}
      />
    );

  return null;
};

export default Link;
